@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;700;900&display=swap');

html {
    box-sizing: border-box;
    height: 100%;
}

*, *::before, *::after {
    box-sizing: inherit;
}

body {
	display: flex;
	flex-direction: column;
	height: 100%;
	font-size: 90%;
	overflow-y: scroll;
	font-family: 'Inter', sans-serif;
	background: #e7e7e7;
	overflow-x: hidden;
	min-width: 320px;
}

.container {
	max-width: 1180px;
	margin: 0 auto;
	padding-left: 1em;
	padding-right: 1em;
}

a {
	cursor: pointer;
}

p {
	margin: 0 0 1em 0;
}

.mt-0 { margin-top: 0 }
.mt-1 { margin-top: 1em }
.mt-2 { margin-top: 2em }
.mt-3 { margin-top: 3em }
.mt-4 { margin-top: 4em }

.mb-0 { margin-bottom: 0 }
.mb-1 { margin-bottom: 1em }
.mb-2 { margin-bottom: 2em }
.mb-3 { margin-bottom: 3em }
.mb-4 { margin-bottom: 4em }

.padh-1 { padding-left: 1em; padding-right: 1em }
.padh-2 { padding-left: 2em; padding-right: 2em }
.padh-3 { padding-left: 3em; padding-right: 3em }
.padh-4 { padding-left: 4em; padding-right: 4em }
.padh-5 { padding-left: 5em; padding-right: 5em }

.padv-1 { padding-top: 1em; padding-bottom: 1em }
.padv-2 { padding-top: 2em; padding-bottom: 2em }
.padv-3 { padding-top: 3em; padding-bottom: 3em }
.padv-4 { padding-top: 4em; padding-bottom: 4em }
.padv-5 { padding-top: 5em; padding-bottom: 5em }

.alert {
	border-radius: $radius;
	margin: 0 0 1em 0;
	padding: 1em;
	display: flex;
	align-items: center;
	i {
		font-size: 1.5em;
	}
	p {
		margin: 0;
		margin-left: 1em;
	}
}
.alert-info {
	background: #ffc;
	color: #774;
}

.loader-wrap {
	display: flex;
	width: 100%;
	flex-basis: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	p {
		text-align: center;
		font-weight: bold;
		color: #777;
		margin: 0;
	}
}

.overlay {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	background: rgba(255,255,255,.85);
	color: #000;
	z-index: 1000;
	p {
		text-align: center;
		font-weight: bold;
		margin: 0;
	}
}
.overlay-inner {
	background: #fff;
	padding: 2em 4em;
	border-radius: $radius*3;
	border: 1px solid #ddd;
	box-shadow: 0 24px 80px rgba(0,0,0,.1);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 1em auto;
  font-size: 1em;
  position: relative;
  text-indent: -9999em;
  border-top: .7em solid #4eb32d;
  border-right: .7em solid #e7e7e7;
  border-bottom: .7em solid #e7e7e7;
  border-left: .7em solid #e7e7e7;
  transform: translateZ(0);
  animation: load8 1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}





