.app {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
	background: #fff;
    flex: 1;
    display: flex;
}

.pages {
	margin: 1em 0;
	text-align: center;
	a, strong {
		display: inline-block;
		padding: 3px 4px;
	}
	a {
		text-decoration: none;
		color: #444;
		&:hover {
			background: #ccc;
		}
	}
	strong {
		background: $primary;
		color: #fff;
	}
}
