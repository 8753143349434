.page {
    width: 100%;
    display: flex;
    flex-direction: column;
    .container {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.page-bg {
    background: #f7f8fa;
}

.page-header {
    text-align: center;
    padding: 1.25em 0 .75em 0;
    background: #eff1f7;
    position: relative;
    .container>h1 {
        font-size: 1.7em;
    }
    .page-header-row {
        display: flex;
        align-items: center;
        padding: 1.5rem 0;
        .lecturetask-course-title {
            margin: 1em 0 0;
            font-size: 1.15em;
            font-weight: 400;
            color: #9fa6b2;
        }        
        h1 {
            font-size: 1.7em;
            color: #253551;
            white-space: nowrap;
            font-weight: 400;
            margin: 10px 0 0 0;
            b {
                font-weight: 900;
            }
        }
        .header-group {
            text-align: left;
            >p {
                margin: 0;
                font-size: .85em;
                a {
                    display: inline-flex;
                    align-items: center;
                    color: $text;
                    font-weight: bold;
                    text-decoration: none;
                    span {
                        text-decoration: underline;
                    }
                    i {
                        margin-right: .3em;
                        font-size: 1.2em;
                    }
                }
            }
        }
        .header-group-buttons {
            display: flex;
            align-items: center;
            a {
                background: $primary;
                color: #fff;
                padding: .5em .85em;
                font-size: 12px;
                border-radius: 4px;
                text-decoration: none;
                &:not(:first-child) {
                    margin-left: .5em;
                }
            }
        }
        aside {
            margin-left: auto;
			a, label {
				display: inline-block;
                border-radius: 3px;
				padding: .85em 1.25em;
				font-size: .85em;
				margin-left: .5em;
                background-image: linear-gradient(#FAFAFA, #F2F2F2);
                border: 1px solid #C5C5C5;
                &:hover {
                    background: #efefef;
                }
			}
            a.accent {
                background: $primary;
                border: 1px solid $primary;
                color: #fff;
                &:hover {
                    background: darken($primary, 5%);
                }
            }
        }
    }

    .page-header-row-2 {
        text-align: left;
        border-top: 1px solid #D9D9D9;
        p {
            margin: 1.5em 0;
            line-height: 100%;
            color: #404040;
            font-size: .85em;
            a {
                margin-left: 2em;
                color: $primary;
                cursor: pointer;
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }

    #cb-page-header-nav {
        display: none;
    }

    .page-header-nav {
        display: block;
        a {
            margin: .25em 0;
        }
    }

    @media screen and (max-width: 959px) {
        .page-header-row {
            h1 {
                font-size: 1.5em;
            }
            aside {
                .page-header-nav-label {
                    display: inline-block;
                    i {
                        margin-right: 0;
                    }
                }
            }
        }
        .page-header-nav {
            display: none;
        }
        #cb-page-header-nav:checked + .page-header-nav {
            display: block;
            position: absolute;
            right: 1em;
            top: calc(100% - .65rem);
            z-index: 110;
            background: #fff;
            box-shadow: 0 12px 40px rgba(0,0,0,.3);
            padding: 0 .5em;
            width: 100%;
            max-width: 260px;
            a, label {
                display: block;
                margin: .5em 0;
            }
        }
    }

    @media screen and (max-width: 719px) {
        .page-header-row {
            flex-direction: column;
            align-items: flex-start;
            aside {
                margin-left: 0;
                margin-top: .75em;
                a:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}

.page-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eff1f7;
    a, label {
        position: relative;
        cursor: pointer;
        top: 1px;
        z-index: 100;
        display: inline-block;
        font-weight: 400;
        padding: .9em 2em;
        border: 1px solid #e0e4eb;
        border-right: none;
        background: #ebebeb;
        color: $text;
    }
    a.active, label.active {
        background: #fff;
        color: #444;
        font-weight: 600;
        border-bottom: 1px solid #fff;
    }
    a:not(.active):hover, label:not(.active):hover {
        background: #f1f1f1;
        color: #6c6c6c;
    }
    a:first-child, label:first-child {
        border-radius: 10px 0 0 0;
    }
    a:last-child, label:last-child {
        border-radius: 0 10px 0 0;
        border-right: 1px solid #e0e4eb;
    }
    label input {
        display: none;
    }
}

.page-content {
    border-top: 1px solid #e0e4eb;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.page-content-noborder {
    border-top: none;
}

.page-content-mobile-nav {
    border-top: none;
}

.page-mobile-nav {
    background: #eff1f7;
}