.login {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;

    .form {
        position: relative;
        max-width: 480px;
        background: #fff;
        padding: 2em 3em;
        margin-bottom: 5em;
        border-radius: $radius*2;
        box-shadow: 0 16px 40px rgba(0,0,0,.13);

        >.login-error {
            text-align: center;
            color: #f00;
        }
    }

    svg {
        display: block;
        width: 70%;
        height: auto;
        margin: 0 auto 1.5em auto;
    }

    .form-row {
        margin: .5em 0 0;
        label {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            input[type="text"], input[type="password"] {
                margin-left: auto;
                width: 80%;
                padding: 10px 12px;
                border-radius: 3px;
                background: #ffa;
                border: 1px solid #bbb;
                box-shadow: inset 1px 3px 4px rgba(0,0,0,.2);
            }
            input[type="checkbox"] {
                margin-right: .25em;
                vertical-align: middle;
                display: none;
            }
            em {
                display: inline-block;
                margin-right: .5em;
                width: 1.5em;
                height: 1.5em;
                line-height: 1.3em;
                text-align: center;
                border: 2px solid #ccc;
                color: #fff;
                font-weight: bold;
                font-style: normal;
                margin-left: 7px;
                border-radius: 5px;
            }
            input[type="checkbox"]:checked ~ em {
                color: $primary;
                border-color: $primary;
            }
            button {
                background: $primary;
                color: #fff;
                border: none;
                border-radius: 3px;
                padding: .7em 2em;
                cursor: pointer;
                &:hover {
                    background: lighten($primary, 4%);
                }
            }
        }
        label.error {
            color: #f00;
            input {
                border: 1px solid #f00;
            }
        }
        &:last-child {
            padding-top: .5em
        }
    }
}