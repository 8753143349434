.input-row-double {
    display: flex;
    .input-row {
        flex-basis: 50%;
    }
}
.input-row-triple {
    display: flex;
    .input-row {
        flex-basis: 33.33%;
    }
}
.input-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4px 0;

    img {
        align-self: center
    }

    span {
        width: 15em;
        flex-basis: 15em;
        margin-top: .5em;
    }
    span.error {
        color: #f00;
        font-weight: bold;
    }

    .inputs {
        width: calc(100% - 15em);
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .row {
            display: flex;
            flex: 1;
            .col {
                padding-right: 1em;
                display: flex;
                align-items: center;
                flex-basis: 1%;
                white-space: nowrap;
            }
            .col-2 {
                //flex-basis: calc(50% - 1em);
            }
            .col-3 {
                //flex-basis: calc(33.33% - 1em);
            }
            .col-4 {
                //flex-basis: calc(25% - 1em);
            }
        }
    }

    input[type="text"], input[type="date"], input[type="email"], input[type="password"], textarea, select {
        width: 100%;
        padding: .35em .5em;
        border: 1px solid #ccc;
        border-radius: $radius;
        margin-right: .5em;
        box-shadow: inset 1px 2px 4px rgba(0,0,0,.1);
        line-height: initial;
    }

    textarea {
        width: 100%;
        height: 8em;
    }

    input.short, textarea.short, select.short {
        width: 6em;
    }  

    label {
        padding: 1em;
    }
}

.input-row-primary {
    font-size: 1.2em;
    input {
        font-weight: bold;
    }
}

.input-row-vtop {
    align-items: flex-start;
    span {
        padding-top: .5em;
    }
}

.form-inline-span {
    span {
        margin-right: .25em;
    }
}

.row-labels-only {
    label {
        padding: 0;
        cursor: pointer;
    }
    .col {
        margin-bottom: .5em;
    }
}

label.check {
    display: flex;
    align-items: center;
    cursor: pointer;
    input {
        display: none;
    }
    b {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75em;
        height: 1.75em;
        font-size: .8em;
        border-radius: 100px;
        border: 1px solid #ccc;
        margin-right: .6em;
        align-self: center;
    }
    i {
        color: #fff;
    }
    input:checked ~ b {
        border-color: $primary;
        background: $primary;
    }
    &:hover {
        b {
            border-color: $primary;
        }
    }
}

.form-submit-plain {
    text-align: center;
    margin-top: 1em;
    padding: 1em;
    button {
        cursor: pointer;
        background: $primary;
        color: #fff;
        padding: 1em 3em;
        font-weight: bold;
        border-radius: $radius;
        border: none;  
        &:hover {
            background: lighten($primary, 5%);
        }      
    }
}

.form-submit {
    text-align: center;
    margin-top: 2em;
    padding: 2em 1em 1em 1em;
    border-top: 1px solid #ccc;
    button {
        cursor: pointer;
        background: $primary;
        color: #fff;
        padding: 1em 3em;
        font-weight: bold;
        border-radius: $radius;
        border: none;  
        &:hover {
            background: lighten($primary, 5%);
        }      
    }
}

.form-confirmation {
    text-align: center;
    h2 {
        font-size: 1.0em;
        font-weight: normal;
        margin: 2em 0 1em 0;
    }
    h1 {
        font-size: 1.6em;
        color: $primary;
    }
}

.compact-labels {
    label {
        padding: .3em .6em;
    }
}



.menu-sort {
    width: 100%;
    max-width: 400px;
}
.menu-sort-item {
    display: flex;
    align-items: center;
    margin: 0 0 .5em 0;
    border: 1px solid #ccc;
    padding: .5em 1em;
    >p {
        flex: 1;
        margin: 0;
        width: 240px;
    }
    aside {
        text-align: right;
        a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 2em;
            height: 2em;
            font-size: .9em;
            margin: 0 0 0 .5em;
            background: #efefef;
            border-radius: 100px;
            &:hover {
                background: $primary;
                color: #fff;
            }
        }

    }
}

.add-meal-option {
    a {
        display: inline-block;
        padding: .5em 1em;
        text-decoration: underline;
        color: $primary;
        border-radius: 3px;
        &:hover {
            background: #eee;
        }
        &.active {
            font-weight: bold;
            text-decoration: none;
            background: #f1f1f1;
            color: #222;
        }
    }
    a:not(:first-child) {
        margin-left: .5em;
    }
}