.filters-advanced-link {
	font-size: .85em;
	padding-left: 1rem;
	label {
		cursor: pointer;
	}
}

.filters-advanced {
	background: #efefef;
	max-height: 0;
	overflow: hidden;
	transition: all .15s ease-out;
	font-size: .8em;
	border-radius: $radius;
	//max-width: 960px;
	margin: 1px auto 1em auto;
	p {
		font-weight: bold;
	}
}
.filters-advanced-row {
	display: flex;
	&:not(:last-child) {
		margin-bottom: 1em;
	}
	p {
		flex-basis: 120px;
		padding-top: 6px;
	}
	div {
		flex: 1;
	}
}

#cb-filters-advanced {
	display: none;
}
#cb-filters-advanced:checked ~ .filters-advanced {
	transition: all .5s ease-out;
	max-height: 1000px;
}
#cb-filters-advanced:checked ~ .input-with-aside {
}


.filters-advanced-item {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 1em;
	label {
		flex-basis: calc(25% - 1em);
		min-width: 170px;
		margin: 0 1em 0 0;
		padding: 2px 0;
		display: inline-flex;
		align-items: center;
		white-space: nowrap;
		cursor: pointer;
		&:hover {
			color: $primary;
			span {
			}
		}
		input {
			display: none;
			margin-right: 2px;
		}
		span {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			font-size: .85em;
			width: 1.6em;
			height: 1.6em;
			background: #fff;
			border: 1px solid $primary;
			color: #fff;
			margin-right: 4px;
			border-radius: 20px;
		}
		input:checked + span {
			background: $primary;
			color: #fff;
		}
	}
}


.input-with-aside {
	position: relative;
	div.tabs {
		font-size: 12px;
		margin: 0;
		text-align: center;
		a, label {
			cursor: pointer;
			position: relative;
			top: 1px;
			z-index: 5;
			display: inline-block;
			padding: .75em 1em;
			margin: 0 6px;
			border-radius: $radius $radius 0 0;
			border: 1px solid transparent;
			border-bottom: 1px solid #ccc;
			&:hover {
				text-decoration: underline;
			}
			&.active {
				font-weight: bold;
				background: #fff;
				border: 1px solid #ccc;	
				border-bottom: 1px solid #fff;
				&:hover {
					text-decoration: none;
				}
			}
			input {
				display: none;
			}
		}
	}
	aside {
		position: absolute;
		right: .5em;
		bottom: .5em;
		// transform: translateY(-50%);
		label {
			font-size: 1.5em;
			display: block;
			padding: .35em .5em;
			background: #eee;
			border-radius: $radius;
		}
	}
}























.filters {
	padding: 1em 0;
	.search-input {
		position: relative;
		input[type="text"] {
			background: transparent linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 47%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
			border: 1px solid #CCCCCC;
			border-radius: 4px;
			font-size: 1.2em;
			padding: 1em 1.5em 1em 3.5em;
		}
		>i {
			position: absolute;
			left: 1em;
			top: 50%;
			transform: translateY(-50%);
			font-size: 1.5em;
			color: #999;
		}
	}
	.with-button {
		display: flex;		
		input[type="text"], textarea, select {
			width: auto;
			flex: 1;
			margin: 0;
			border-radius: $radius 0 0 $radius;
			border-right: none;
		}
		button, label {
			display: flex;
			align-items: center;
			justify-items: center;
			font-size: 1.05em;
			padding: 1em 1.5em;
			border-radius: 0 $radius $radius 0;
			margin: 0;
			cursor: pointer;
			background: transparent linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 47%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
			border: 1px solid #ccc;

			&:hover {
				background: #efefef;
			}
			i {
				font-size: 1.2em;
				color: $primary;
				margin-right: .5rem;
			}
		}
	}
	input[type="text"], textarea, select {
		display: block;
		width: 100%;
		padding: $padding*.65 $padding*.75;
		border-radius: $radius;
		border: 1px solid #ccc;
		margin: 0 0 .5em 0;
	}
	button {
		display: block;
		padding: $padding*.65 $padding*.75;
		border-radius: $radius;
		border: 1px solid #ccc;
	}
	.filter-options {
		border-radius: $radius;
		margin: 0 0 .5em 0;
		&:last-child {
			margin-bottom: 0;
		}
		>label {
			cursor: pointer;
			display: block;
			padding: $padding/2;
			span {
				font-size: 10px;
				background: #aaa;
				color: #fff;
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 1.4em;
				height: 1.4em;
				border-radius: 50%;
			}
		}
		>input[type="checkbox"] {
			display: none;
		}
		>input[type="checkbox"]:checked ~ .filter-options-body {
			display: block;
		}
		>input[type="checkbox"]:checked + label {
			i {
				transform: rotate(90deg);
			}
		}
		.filter-options-body {
			display: none;
			padding: 0 $padding;
			label {
				display: block;
			}
		}
	}
	.filter-options-generic {
		font-size: .9em;
		margin: 1em 0 0;
		a {
			display: inline-block;
			margin-left: .5em;
			padding: 3px 4px;
			background: #f1f1f1;
			border: 1px solid #f1f1f1;
			color: #777;
		}
		a.active {
			background: #fff;
			border: 1px solid $primary;
			color: $primary;
		}
	}
}

.filters-inline {
	margin: 1em 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	>a, .switch {
		margin-right: 1em;
	}
	a {
		padding: .35em .6em;
		border-radius: $radius;
		background: $primary;
		border: 1px solid $primary;
		color: #fff;
		text-decoration: none;
		&:hover {
			background: lighten($primary, 10%);
			color: #fff;
		}
	}
	.button-list {
		margin-right: 1.5em;
		em {
			font-style: normal;
			font-size: .9em;
		}
		a {
			font-size: 1em;
			padding: .2em .3em;
			border-radius: $radius;
			border: 1px solid transparent;
			margin-left: 5px;
			background: none;
			color: #000;
			&:hover {
				border: 1px solid #ccc;
			}
		}
		a.active {
			color: $primary;
			background: $primary-lightest;
		}
	}

	.switch {
		a {
			display: inline-block;
			color: #444;
			border-top: none;
			border-bottom: none;
			border-left: none;
			border-right: 1px solid #bbb;
		}
		a:hover {
			background: #ddd;
		}
		a.active {
			color: $primary;
		}
		a:first-child {
			border-radius: $radius 0 0 $radius;
			border-left: none;
		}
		a:last-child {
			border-radius: 0 $radius $radius 0;
			border-right: none;
		}
	}
    aside {
		margin-left: auto;
    }

	@media screen and (max-width: 639px) {
		flex-wrap: wrap;
		aside {
			margin-left: 0;
			flex: 1;
			order: 1;
		}
		.button-list {
			order: 2;
			em {
				display: none;
			}
		}
	}
	@media screen and (max-width: 449px) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		aside {
			flex-basis: 100%;
			margin-bottom: 1.5em;
			text-align: center;
		}
		.button-list {
			order: 2;
			em {
				display: none;
			}
		}
	}
}

