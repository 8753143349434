.client-detail {
    article {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: .5em;
        margin-bottom: .5em;
        p {
            margin: 0 1em 0 0;
        }
        img {
            width: 70px;
            height: 70px;
            border-radius: 80px;
            display: block;
        }
        h1 {
            font-size: 1.5em;
            margin: 0;
            color: $primary;
        }
    }
    table {
        width: 100%;
        th {
            width: 180px;
        }
        td, th {
            text-align: left;
            font-weight: normal;
            vertical-align: top;
            padding: .25em .5em;
        }
        td {
            font-weight: bold;
        }
    }
    select, input[type="text"], input[type="date"] {
        padding: 4px 6px;
        border-radius: 3px;
        border: 1px solid #aaa;
        width: 100%;
        max-width: 20em;
    }
    .links {
        display: flex;
        margin: 0 0 1em -1em;
        a {
            display: flex;
            flex-basis: calc(33.33% - 1em);
            margin-left: 1em;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: .75em 1em;
            border-radius: 4px;
            border: 1px solid #ccc;
            background: transparent linear-gradient(180deg, #F7F7F7 0%, #FFFFFF 47%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
            color: #333;
            span {
                font-size: 1.5em;
                color: $primary;
                margin-bottom: .5rem;
            }
            &:hover {
                background: $primary;
                color: #fff;
                span {
                    color: #fff;
                }
            }
        }
        a:not(:last-child) {
            margin-bottom: .25em;
        }
    }
}

.profi-client-notes {
    p {
        margin: .5em 0;
    }
    textarea {
        width: 100%;
        height: 17em;
    }
    button {
        background: $primary;
        color: #fff;
        padding: 1em 3em;
        cursor: pointer;
        border: none;
        border-radius: 3px;
    }
}

.profi-planner-client-switch {
    padding: 1em;
    background: #fff;
    margin: 0 0 1em 0;
    border-radius: $radius;
    select {
        padding: .25em 2em .25em .5em;
        border: 1px solid #bbb;
    }
}


.client-data-list {
    margin: 2em 0 0;  
}
.client-data-item-header {
    font-weight: bold;
    >div {
        background: #efefef;
    }
}
.client-data-item {
    border: 1px solid #eee;
    margin-bottom: 1em;
    >p {
        background: #eee;
        padding: .5em 1em;
    }
    .row {
        padding: .5em 1em;
        display: flex;
        flex-wrap: wrap;
        p {
            margin: 0;
            padding: 4px 0;
            flex-basis: 50%;
            display: flex;
            span {
                flex-basis: 240px;
            }
        }
    }
    .options {
        padding: 0 1em 1em 1em;
        a {
            background: $primary;
            color: #fff;
            padding: 3px 5px;
            border-radius: 3px;
        }
    }
}

.group-detail {
    >p {
        color: $primary;
        margin: 0 0 1em 0;
    }
    .members-list {
        height: 280px;
        overflowY: 'scroll';
    }
    .member {
        display: flex;
        align-items: center;
        border-top: 1px solid #ddd;
        padding: .5em .15em;
        &:hover {
            background: #efefef;
        }
        p {
            margin: 0;
        }
        aside {
            margin-left: auto;
        }
    }
}































