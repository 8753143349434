.food-row {
	display: flex;
	position: relative;
	align-items: center;
	margin: 0 0 .5em 0;
	box-shadow: inset 0 8px 16px rgba(0,0,0,.025);
	background: #fff;

	&:last-child {
		margin-bottom: 0;
	}

	>div {
		margin-right: 1em;
		&:last-child {
			margin-right: 0;
		}
	}
	.options {
		display: flex;
		// align-items: center;
		flex-direction: column;
		justify-content: flex-start;
		.weight-input {
			position: relative;
		}
		.weight-range-info {
			position: absolute;
			left: 0;
			bottom: 100%;
			width: auto;
			text-align: center;
			background: #6b6b6b;
			color: #fff;
			padding: 0 5px;
			border-radius: 3px;
			z-index: 1000;
			p {
				white-space: nowrap;
				margin: 5px 0;
			}
			p:first-child {
				font-size: 13px;
			}
			p:last-child {
				font-size: 11px;
				font-weight: 300;
			}
			span {
				font-size: 11px;
			}
			&:before {
				content: "";
				position: absolute;
				left: 50%;
				bottom: -6px;
				transform: translateX(-50%);
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 6px 6px 0 6px;
				border-color: #6b6b6b transparent transparent transparent;
			}
		}
		.normalized-unit-info {
			padding: 4px 0 0;
			font-size: 11px;
			font-style: italic;				
		}		
		input[type="text"] {
			width: 5em;
			text-align: right;
			padding: .4em .6em;
			border: 1px solid #bbb;
			border-radius: 3px;
			box-shadow: inset 1px 2px 3px rgba(0,0,0,.15);
		}
		input[type="text"].invalid {
			border: 1px solid #f00;
			background: #f00;
			color: #fff;
		}
		span {
			margin: 0 1em 0 .5em;
		}
		select {
			margin-right: 1em;
		}
		label {
			margin-right: 1em;
			input {
				vertical-align: middle;
			}
		}
	}
	.modifications {
		min-width: 104px;
		a {
			cursor: pointer;
			color: #000;
			padding: .5em 1em;
			border-radius: 3px;
			span {
				margin-left: .1em;
				background: $primary;
				color: #fff;
				font-size: .9em;
				font-weight: bold;
				padding: 2px 4px;
				border-radius: 3px;
			}
			&:hover {
				background: #fff;
			}
		}
	}
	.modifications-selected {
		a {
			background: #fff;
			border-radius: 3px 3px 0 0;
			box-shadow: 0 4px 8px rgba(0,0,0,.15);
		}
	}
	.buttons {
		margin-left: auto;
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 20px;
		display: flex;
		flex-direction: column;
		a {
			color: #bbb;
			text-decoration: none;
			text-align: center;
			display: inline-block;
			width: 2em;
			height: 2em;
			line-height: 2em;
			font-size: 9px;
			text-align: center;
			&:hover {
				color: #222;
			}
			&.item-remove:hover {
				color: #f00;
			}
		}
	}
	.icon {
		flex-shrink: 0;
		flex-grow: 0;
		flex-basis: 80px;
		margin-right: .25em;
		//border-radius: 3px;		
		img {
			height: 85px;
			width: auto;
			display: block;
			position: relative;
			background: #fff;
			border-radius: 3px;
			padding: 10px;
			transition: all .15s;
			// border: 1px solid #ddd;
			/*
			&:hover {
				z-index: 500000;
				transform: translateX(34%) scale(1.7);
				transition: all .3s;
				box-shadow: 0 4px 8px rgba(0,0,0,.1);
			}
			*/
		}
	}
	.title {
		flex-shrink: 1;
		flex-grow: 1;
		flex-basis: 15em;
		padding: .5em 1em .5em .25em;
		h4 {
			font-size: 1.15em;
			margin: 0 0 .1em 0;
			font-weight: 600;
			color: $primary;
			a {
				display: inline-block;
				width: 1.2em;
				height: 1.2em;
				line-height: 1.35em;
				font-size: 12px;
				text-align: center;
				background: #999;
				color: #fff;
				text-decoration: none;
				border-radius: 100px;
				vertical-align: top;
				&:hover {
					background: $primary;
				}
			}
		}
		p {
			margin: 0;
			font-size: .85em;
			select {
				width: calc(100% - 2.5em);
				border: 1px solid #bbb;
				border-radius: 3px;
				padding: .25em .2em;
				cursor: pointer;
			}
		}
		select {
			cursor: pointer;
		}
	}
}




.food-detail {
	h2 {
		font-weight: 300;
	}
}

.food-detail-heading {
	display: flex;
	align-items: center;
	// justify-content: center;
    margin: 1em 0 1em 0;
	img {
		width: 60px;
		height: auto;
	}
	h1 {
		font-size: 2.2em;
		font-weight: bold;
		margin: 0 0 0 2rem;
	}
}

.food-detail-values {
	display: flex;
	flex-wrap: wrap;
	margin: 0 0 1em -2em;
}
.food-detail-values .value {
	flex-basis: calc(33.33% - 2em);
	margin: 0 0 2em 2em;
	text-align: center;
	box-shadow: 0 8px 22px rgba(0,0,0,.1);
	background: -webkit-linear-gradient(top left, rgba(255,255,255,1) 0%, rgba(237,237,237,1) 100%);
	background: -o-linear-gradient(top left, rgba(255,255,255,1) 0%, rgba(237,237,237,1) 100%);
	background: linear-gradient(to bottom right, rgba(255,255,255,1) 0%, rgba(237,237,237,1) 100%);
}
.food-detail-values .value p:first-child {
	margin: 0;
	padding: .5em 1em;
	color: #fff;
	font-size: .9em;
	background: #4eb32d;
	background: -moz-linear-gradient(top,  rgba(78,179,45,1) 0%, rgba(37,142,40,1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba(78,179,45,1) 0%,rgba(37,142,40,1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba(78,179,45,1) 0%,rgba(37,142,40,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.food-detail-values .value p:last-child {
	font-size: 2em;
	margin: 0;
	padding: .5em;
	line-height: 100%;
	border: 1px solid #ddd;
	border-top: none;
}
.food-detail-values .value strong {
	display: block;
	letter-spacing: -1px;
}
.food-detail-values-info {
	text-align: center;
	margin: 0 0 2em 0;
	font-size: 12px;
	color: #666;
}

.food-detail-graphs {
	display: flex;
	justify-content: space-between;
	padding: 0 0em;
	margin-bottom: 2em;
}
.food-detail-graphs .graph {
	flex-basis: calc(50% - 6em);
}
.food-detail-graphs .graph h3 {
	font-size: 1.1em;
	font-weight: 100;
}

@media screen and (max-width: 1099px) {
	.food-detail-values {
		margin: 0 0 1em -1em;
	}
	.food-detail-values .value {
		flex-basis: calc(50% - 1em);
		margin: 0 0 1em 1em;
	}
	.food-detail-graphs {
		flex-wrap: wrap;
	}
	.food-detail-graphs .graph {
		flex-basis: 100%;
	}
	.food-detail-graphs .graph svg {
		max-width: 480px;
	}
}
@media screen and (max-width: 959px) {
	.food-detail-values {
		flex-wrap: wrap;
	}
	.food-detail-values .value {
		flex-basis: calc(50% - 1em);
		margin-bottom: 2em;
	}
}
@media screen and (max-width: 719px) {
	.food-detail-values .value p:last-child {
		padding: 1em 0;
		font-size: 1.3em;
	}
}


.food-validation-errors {
	margin: 1em 0;
	background: #fee;
	color: #f00;
	font-weight: bold;
	padding: 1em;
}

.item-info-undesirable {
	display: flex;
	font-size: .85em;
	margin: 2px 0 0;
	color: #f00;
	.item-info-icon {
		background: none;
		color: #f00;
		padding: 0;
	}
	.item-info-undesirable-profiles {
	}
	span {
		font-size: .85em;
		margin-right: .5em;
		font-weight: bold;
		text-transform: uppercase;
	}
}
