.profi-advisor-mini-profile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: .5em;
    img {
        width: 36px;
        height: 36px;
        border-radius: 32px;
        margin-right: 1em;
    }
    p {
        margin: 0;
    }
}


.client-page-wrap {
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding: 1em 0;
}

.client-basic-table {
    width: 100%;
    margin: 0 0 1em 0;
    border: 1px solid #ddd;
    border-bottom: none;
    border-collapse: collapse;;
    td, th {
        padding: .65em 2em .65em 1em;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    th {
        width: 240px;
        text-align: right;
        background: #eee;
    }
}

.client-notes {
    textarea {
        width: 100%;
        height: 25em;
        padding: .5em;
        box-shadow: inset 1px 4px 6px rgba(0,0,0,.1);
        border-radius: 2px;
        border: 1px solid #aaa;
    }
    button {
        @include button_default();
        border: none;
    }
}