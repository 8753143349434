.lecture-intro {
    line-height: 150%;
    margin: 1em auto;
    max-width: 680px;
    p.begin {
        text-align: center;
        margin: 3em 0 2em 0;
        a {
            @include button_default();
            padding: .85em 2.5em;
            font-weight: bold;
        }
    }
}

.lecture-intro-profile {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid #ddd;
    h3 {
        font-size: 1.2em;
        margin: 0 0 1em 0;
        color: $primary;
    }
    table {
        margin: 1em 0;
        td {
            padding: 2px 1em 2px 0;
        }
    }
}

.lecturetask-item-new {
    margin: 0 0 1em 0;
    display: flex;
    align-items: center;
    p {
        margin: .5em 0;
    }
    input[type="text"] {
        font-size: .9em;
        padding: .5em 1em;
        border-radius: 4px;
        border: 1px solid #bbb;
        margin-right: .5em;
        font-weight: bold;
        flex: 1;
    }
    a, button {
        @include button_default();
        border: none;
        font-size: .9em;
        font-weight: bold;
    }
    .input-label {
        font-size: .9em;
        padding: .5em 1em;
        background: #e6e6e6;
        border: 1px solid #bbb;
        border-right: none;
        color: #000;
        border-radius: 4px 0 0 4px;
    }
    .input-label + input {
        border-radius: 0 4px 4px 0;
    }
}

.lecturetask-item {
    display: flex;
    align-items: center;
    background: #efefef;
    padding: .5em 1em;
    margin-bottom: .5em;
    h2 {
        font-size: 1em;
        font-weight: bold;
        margin: 0;
    }
    p {
        font-size: .9em;
        color: #444;
        margin: .5em 0 0;
    }
    p.info {
        font-size: .85em;
    }
    article {

    }
    aside {
        margin-left: auto;
        font-size: .85em;
        a {
            display: inline-block;
            padding: .5em .65em;
            background: $primary;
            color: #fff;
            text-decoration: none;
            border-radius: 4px;
            margin-left: 4px;
        }
        a.rename {
            background: #999;
        }
        a.delete {
            background: #f34;
        }
    }
}

.lecturetask-header-groups {
    display: flex;
    .group {
        padding: 1em 1.5em;
        border: 1px solid $border;
        border-radius: $radius;
        text-align: left;
        font-size: .85em;
        display: flex;
        align-items: center;
    }
    p {
        margin: 0 0 .35em 0;
    }
    p:last-child {
        margin: 0;
    }
    .group + .group {
        margin-left: 1rem;
    }
    a {
        color: $primary;
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
    }
}



.lecturetask-task {
    margin: 0;
    display: flex;
    >span {
        display: flex;
        width: 1.3em;
        height: 1.3em;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
    }
    i {
        font-size: .75em;
    }
    p {
        margin: 0 0 0 .5rem;
    }
}
.lecturetask-task.ok {
    color: #8894a6;
    >span {
        background: #f6f9fd;
    }
    i {
        color: #8894a6;
    }
}
.lecturetask-task.fail {
    i {
        opacity: 0;
    }
}

.lecturetask-task + .lecturetask-task {
    margin-top: 5px;
}

.lt-heading {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 0 1em 0;
    padding-bottom: 4px;
    border-bottom: 1px solid $border;
    h3 {
        font-size: 1em;
        margin: 0;
        color: $text;
        text-transform: uppercase;
        font-weight: 800;
    }
    label {
        cursor: pointer;
        margin-left: .65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        width: 1.4em;
        height: 1.4em;
        font-weight: bold;
        border: 1px solid #777;
        color: #777;
        border-radius: 50px;
        &:hover {
            color: #000;
            border: 1px solid #000;
        }
    }
    input[type="checkbox"] {
        display: none;
    }
}
.lt-help {
    position: absolute;
    display: none;
    background: #fff;
    font-size: 12px;
    line-height: 150%;
    padding: 1.5em;
    user-select: none;
    border-radius: 10px;
    left: -4px;
    top: calc(100% + .7em);
    border: 1px solid #ddd;
    z-index: 10000;
    &:before {
        content: "";
        position: absolute;
        z-index: 2;
        left: 3em;
        top: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent #ccc transparent;        
    }
    &:after {
        content: "";
        position: absolute;
        z-index: 3;
        left: 3em;
        top: -8px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent #ffffff transparent;        
    }
    p:last-child {
        margin-bottom: 0;
    }
}
input[type="checkbox"]:checked + .lt-help {
    display: block;
}

.lecturetask-eval-heading {
    color: $text;
    margin: 0;
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    font-size: 1em;
    font-weight: 800;
    text-transform: uppercase;
}

.lecturetask-eval-box {
    position: relative;
    margin: 0 0 2em 0;
}



.watch-attribute-row {
    display: flex;
    p {
        margin: 0;
        padding: 2px 0;
    }
    aside {
        margin-left: auto;
        text-align: right;
    }
}

.lecturetask-note {
    >h2 {
        font-size: 1.4em;
        display: flex;
        align-items: center;
        a {
            font-size: 1em;
            border: 1px solid #aaa;
            color: #888;
            border-radius: 100px;
            display: flex;
            width: 1.25em;
            height: 1.25em;
            align-items: center;
            justify-content: center;
            margin-right: .5rem;
            &:hover {
                background: #aaa;
                color: #fff;
            }
        }
    }
    textarea {
        display: block;
        width: 100%;
        height: 15em;
    }
    button {
        @include button_default();
        margin: 1em 0 0;
        border: none;
        font-size: .9em;
    }
}

.lecturetask-inside-meal {
    span.ddd-limits {
        // display: none;
    }
    .lecturetask-task>span {
        opacity: 0;
    }
}

.lecturetask-complete {
    margin: 1em 0 1em 1em;
    padding: 1em 0 2em 0;
    text-align: center;
    border-radius: 4px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    i {
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75em;
        height: 1.75em;
        margin: 0 auto;
        border-radius: 200px;
        color: #4eb32d;
        font-size: 3.3em;
    }
    .cg {
        font-size: 1.15em;
    }
}

.lecturetask-foodslist {
    font-size: .9em;
    line-height: 120%;
    p {
        margin: 4px 0 0;
    }
    >div {
        margin-bottom: 1em;
    }
    >div:last-child {
        margin-bottom: 0;
    }
}


.lecturetask-day-title {
    position: relative;
    margin-left: .5em;
    >input {
        display: none;
    }
    >div {
        display: none;
        position: absolute;
        z-index: 1500;
        left: 0;
        top: calc(100% + 6px);
        font-size: .9rem;
        background: #fff;
        border: 1px solid #eaeaea;
        a {
            display: block;
            color: #000;
            padding: .5em 2.5em .5em 1em;
            text-decoration: none;
        }
        a:hover {
            background: $primary-lightest;
        }
        a:not(:last-child) {
            border-bottom: 1px solid #eaeaea;
        }
    }
    >input:checked + div {
        display: block;
    }
}

.lecturetask-watch-group {
    margin: 0 0 .5em 0;
    h2 {
        font-size: 1em;
        font-weight: bold;
    }
    >input {
        display: none;
    }
    label {
        cursor: pointer;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding: 2px 0;
        i {
            margin-right: 5px;
        }
    }
    >input:checked + label {
        i {
            transform: rotate(90deg);
        }
    }
    .wcontent {
        display: none;
    }
    >input:checked ~ .wcontent {
        display: block;
    }
}

.tasklist-wrapped-seminarwork {
    .lecturetask-task {
        span {
            display: none;
        }
        p {
            margin-left: 0;
        }
    }
}