main nav {
    position: relative;
    margin-left: calc((100% - 100vw) / 2);
    width: 100vw;
    .container {
        display: flex;
        align-items: center;
        padding-top: .5em;
        padding-bottom: .5em;
    }
    p {
        margin: 0;
    }
    aside {
        margin-left: auto;
        a {
            @include button_default();
            margin-left: .5em;
        }    
    }
}
