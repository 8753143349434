#cb-panel-evaluation {
	display: none;
}

.eval-item-single {
	display: flex;
	align-items: center;
	font-size: 11px;
	a {
		flex-basis: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		position: relative;
		padding: .1em .5em;

		span {
			flex-basis: 112px;
			margin-right: 1em;	
		}

		em {
			flex-basis: 60px;
			margin-right: 1em;
			font-style: normal;
		}

		&:before {
			content: "?";
			background: none;
			display: flex;
			width: 1.25em;
			height: 1.25em;
			align-items: center;
			justify-content: center;
			color: #aaa;
			font-size: 10px;
			border: 1px solid #aaa;
			border-radius: 50%;
			position: absolute;
			right: 1px;
			top: 1px;
			z-index: 1;
		}

		&:hover {
			background: #fff;
			&:before {
				border: 1px solid $primary;
				color: $primary;
			}
		}
	}
}

.eval-item-pair {
	display: flex;
	align-items: center;
	a {
		flex-basis: 50%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		position: relative;
		padding: .5em;

		strong {
			display: block;
			flex-basis: 100%;
			text-align: center;
			font-size: 1.2em;
			color: #000;
		}

		&:before {
			content: "?";
			background: none;
			display: flex;
			width: 1.25em;
			height: 1.25em;
			align-items: center;
			justify-content: center;
			color: #aaa;
			font-size: 10px;
			border: 1px solid #aaa;
			border-radius: 50%;
			position: absolute;
			right: 1px;
			top: 1px;
			z-index: 1;
		}

		&:hover {
			background: #fff;
			&:before {
				border: 1px solid $primary;
				color: $primary;
			}
		}

	}
}

.evaluation-group {
	line-height: 100%;
	margin-top: 1px;
	margin-right: -1rem;
	label {
		display: block;
		padding: 2px 5px;
	}
	input[type="checkbox"] {
		display: none;
	}
	.evaluation-group-title {
		cursor: pointer;
		background: $primary-lightest;
		font-weight: bold;
		color: #000;
		padding: 3px 4px;
		font-size: .9em;
		margin: 0;
		label {
			cursor: pointer;
		}
	}
	.evaluation-group-content {
		color: #444;
		padding-right: .25rem;
		b, strong {
			font-weight: normal;
		}
	}
	.row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		margin: 4px 0;
		padding: 4px 0;
		.title {
			flex-basis: 190px;
			font-weight: bold;
		}
		.value {
			flex: 1;
			flex-basis: 100%;
		}
	}
}

.evaluation-group-closed {
	//margin: .5em 14px .5em 0;
	.evaluation-group-content {
		display: none;
	}
	input[type="checkbox"]:checked + p {
		label {
			i {
				transform: rotate(90deg);
			}
		}
	}
	input[type="checkbox"]:checked ~ .evaluation-group-content {
		display: block;
	}
}

.evaluation-recommendations {
	padding: 1em 1em 0 1em;
	h2 {
		font-size: 1.5em;
		margin: 0 0 1em 0;
		color: $primary;
	}
	h3 {
		font-size: 1em;
	}
	table {
		width: 100%;
		td {
			padding: 2px 4px 2px 0;
		}
		td:first-child {
			width: 50%;
		}
		td:last-child {
			width: 50%;
			text-align: right;
		}
	}
}

.evaluation-toggle-advanced {
	margin: 2em 0 1em 0;
	text-align: center;
	a {
		display: inline-block;
		padding: .5em 1em;
		background: $primary-lightest;
		border: 1px solid $primary;
		text-decoration: none;

	}
}
