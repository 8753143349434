
$calBorderColor: #ccc;
$calNeutralColor: rgba(255,255,255,.8);
$borderColor: #bbb;

.simple-calendar-wrap {
	width: 100%;
	flex-basis: 100%;
	flex: 1;
	display: flex;
	padding-bottom: 1em;

	.row {
		display: flex;
	}

    .simple-calendar {
		width: 100%;
		// background: #fff;
    }

	.calendar-row {
		display: flex;
		width: 100%;
	}

	.row-middle {
		align-items: center;
	}

	.col {
		flex-grow: 1;
		flex-basis: 0;
        max-width: 100%;
        text-align: center;
	}

	.col-start {
		justify-content: flex-start;
		text-align: left;
	}

	.col-center {
		justify-content: center;
		text-align: center;
	}

	.col-end {
		justify-content: flex-end;
		text-align: right;
	}

	.header {
		padding: .5em 1em;
		background: #fff;
        color: #444;
        display: flex;
        align-items: center;
        white-space: nowrap;
        border-radius: $radius $radius 0 0;
        .col {
            padding: .5em;
            text-transform: capitalize;
        }
        .col-center {
        }
        .icon {
            cursor: pointer;
        }
    }
    
    .days {
		background: #fff;
        font-size: 11px;
        .col {
            padding: .75em .85em;
        }
    }

    .body {
		border-top: 1px solid #efefef;
		background: #fff;
        .col {
            font-size: .9em;
            border-right: 1px solid #efefef;
            a {
                padding: .85em;
				display: block;
				position: relative;
                &:hover {
                    background: $primary-lightest;
                }
            }
            &.today a {
                font-weight: bold;
            }
            &.active a {
				background: #eaeaea;
                color: #000;
			}
			.minimap-hour {
				position: absolute;
				right: 1px;
				display: flex;
				div {
					margin-left: 1px;
				}
			}
        }

        .other-month {
            color: #ccc;
        }
    }

    .calendar-row {
        border-bottom: 1px solid #efefef;
    }

	.calendar .body {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	.calendar .body .cell {
		position: relative;
		overflow: hidden;
		cursor: pointer;
		background: rgba(255,255,255,.85);
		border-right: 1px solid $borderColor;
		&:last-child {
			border-right: none;
		}
		.options {
			display: none;
			position: absolute;
			left: 0;
			bottom: 0;
			font-size: 12px;
			a {
				color: #888;

				display: inline-block;
				padding: 6px;
				&:hover {
					color: $primary;
				}
			}
		}
	}


	.calendar .body .calendar-row {
		//border-bottom: 1px solid #000;
		flex: 1;
		border-bottom: 1px solid $borderColor;
		border-left: 1px solid $borderColor;
		border-right: 1px solid $borderColor;
		.col {
			flex-basis: calc(100% / 7);
		}
	}

	.calendar .body .cell .number {
		position: absolute;
		right: .15em;
		top: .15em;
		padding: .25em;
		border-radius: 3px;
		font-size: .85em;
		font-weight: normal;
		color: #777;
	}

	.calendar .body .cell .icon-big {
		font-size: 3em;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		color: #ccc;
	}

	.calendar .body .disabled {
		color: #bbb;
		pointer-events: none;
	}

	.calendar .body .active {
		.number {
			font-weight: bold;
			background: $primary;
			color: #fff;
		}
	}

	.calendar .body .other-month {
		pointer-events: none;
		background: rgba(255,255,255,.25);
		.number {
			opacity: 0;
		}
	}

	.calendar .body .col {
		display: flex;
		flex-direction: column;
		flex: 1;
		flex-basis: calc(100% / 7);
        //min-height: 60px;
	}	

	.calendar .body .cell p {
	}

	
	.col-body {
		//padding: 6px;
	}

	.profile-row {
		margin: 0;
		position: relative;
		font-size: 12px;
		a {
			display: block;
			padding: 6px;
		}
	}

	.cell-centered {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		color: $primary;
		p {
			margin: .2em 0;
			display: flex;
			align-items: center;
		}
		i {
			margin-right: .2em;
			line-height: 100%;
		}
	}
}

