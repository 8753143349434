$primary: #26AA52;
$primary-lightest: #e9f5e5;
$primary-dark: #4f842d;
$background-light: #f7f7f7;

$text: #404040;
$text-light: #737373;
$link: #1a9807;
$button: #4ebe55;
$border: #bdc7d5;
$border-light: #ebebeb;

// $text: #111;
$radius: 4px;
$padding: 1em;

@mixin clearfix() {
  & {
      *zoom: 1;
  }
  &:before,
  &:after {
      content: "";
      display: table;
  }
  &:after {
      clear: both;
      content: "";
      display: table;
  }
}

@mixin button_default()
{
  white-space: nowrap;
  font-weight: 400;
  font-size: 1rem;
  background: $primary;
  cursor: pointer;
  color: #fff;
  padding: .5rem .75rem;
  text-decoration: none;
  border-radius: $radius;
  text-align: center;
  i {
    margin-right: .5rem;
  }
  &:hover {
    background: lighten($primary, 5%);
    color: #fff;
  }  
}


.button-default {
  @include button_default();
}

.button-primary {
  @include button_default();
}

.buttons a {
  @include button_default();
  text-decoration: none;
  background: none;
  padding: 0;
  &:hover {
    background: none;
    color: #000;
  }
}


@keyframes slideDown {
  0% { transform: translateX(-50%) translateY(-2em); }
  100% { transform: translateX(-50%) translateY(0); }
}

.app .message {
  position: fixed;
  display: flex;
  align-items: center;
  top: 1em;
  left: 50%;
  transform: translateX(-50%) translateY(0);
  z-index: 10000010;
  background: #fff;
  padding: 1.5em 1.5em;
  box-shadow: 0 4px 16px rgba(0,0,0,.2);
  border-radius: 12px;
  animation: slideDown .2s normal forwards ease-in;
  >i {
      margin-right: .5em;
  }
  p {
      margin: 0;
  }
  a {
      margin-left: 1em;
      color: #aaa;
  }
}

.message-info>i {
  color: #777;
}
.message-error {
  background: #f14 !important;
  color: #fff;
}
.message-error>i {
  color: #fff;
}
.message-error a {
  color: #fff !important;
}
.message-success>i {
  color: #0c0;
}


.form-required {
    font-weight: bold;
    color: #4eb32d;
    margin-left: .5rem;
}



.public-DraftEditor-content {
  min-height: 250px;
}


.btn-help {
  font-size: .85em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5em;
  height: 1.5em;
  background: #efefef;
  border: 1px solid #ccc;
  border-radius: 100px;
  &:hover {
    background: #fff;
  }
}


.generic-table {
  width: 100%;
  td, th {
    //vertical-align: top;
    text-align: left;
    font-weight: normal;
    padding: 4px 1em 4px 0;
  }
  th {
    width: 130px;
  }
  input, textarea, select {
    padding: .25em .4em;
    box-shadow: inset 1px 2px 3px rgba(0,0,0,.15);
    border: 1px solid #ccc;
  }
  button {
    background: $primary;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: .5em 1em;
    border-radius: $radius;
    &:hover {
      background: lighten($primary, 7%);
    }
  }
}


.page-register {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
  margin: 2em 0;
  text-align: center;
  .register-header {
    padding: 0 0 2em 0;
    h1 {
      margin: 0 0 .25em 0;
      color: $primary;
    }
  }
  .register-body {
    background: #fff;
    padding: 2em;
  }
}

.longterm-evaluation-row {
  background: #f1f1f1;
  margin: -.5em;
  padding: 1em;
  display: flex;
  align-items: center;
  .col {
    padding: 0 2em 0 0;
    &:last-child {
      padding: 0;
    }
  }
  label {
    cursor: pointer;
    margin: 0 1em 0 0;
    &:last-child {
      margin: 0;
    }
  }
  button {
    background: $primary;
    color: #fff;
    border: none;
    padding: .5rem 1.25rem;
    border-radius: $radius;
    cursor: pointer;
  }
}

.myfood-edit-advanced-row {
  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-top: .5em;
    font-size: .9em;
    border-radius: 4px;
    padding: .6em 0 !important;
    input {
      margin-right: .5em;
    }
    &:hover {
      font-weight: bold;
    }
  }
}


.recommended-wrap {
  >h3 {
    color: #000;
    margin: 2em 0 1em 0;
    font-size: 1.1em;
    &:first-child {
      margin-top: 0;
    }
  }
}

.recommended {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2em;  
}

.recommended-item {
  display: inline-flex;
  align-items: center;
  flex-basis: 100%;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin: 0 .5em .5em 0;
  padding: .25em .5em;
  .picture {
    flex-basis: 50px;
    height: 50px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .title {
    flex-basis: calc(100% - 60px - 1em);
    p {
      margin: 0;
      font-size: .9em;
    }
  }
  &:hover {
    border: 1px solid $primary;
    background: $primary-lightest;
  }
}
.recommended-item-no-hover {
  &:hover {
    background: none;
    border: 1px solid #ccc;
  }
  .meal-links {
    margin: .5em 0 !important;
    a {
      cursor: pointer;
      padding: 2px 3px;
      margin-right: .35em;
      background: $primary;
      color: #fff;
      border-radius: $radius;
    }
  }
}

.recommended-meal {
  h3 {
    color: $primary;
  }
  a {
    display: inline-block;
    padding: .5em 1.5em;
    background: $primary;
    color: #fff;
    text-decoration: none;
    border-radius: $radius;
  }
}

.table-foods-contents {
  font-size: .9em;
  margin-bottom: 1em;
  border: 1px solid #ccc;
  border-bottom: none;
  border-collapse: collapse;
  td, th {
    padding: 4px 7px;
    border-bottom: 1px solid #ccc;
    text-align: left;
  }
  td:first-child {
    padding-right: 1em;
  }
}

