.recipe-editor-row {
    display: flex;
    flex: 1;
}

.recipe-editor-limited-row {
    max-width: 740px;
    margin: 0 auto;
}


.recipe-editor-heading {
    color: $primary;
    border-bottom: 1px solid #eee;
    background: #fff;
    .container {
        display: flex;
        max-width: calc(740px - 2em);
        flex-direction: row !important;
        align-items: center;
        justify-content: space-between;
        padding-top: 1em;
        padding-bottom: 1em;
    }    
    h1 {
        font-size: 1.1em;
        font-weight: bold;
        margin: 0;
    }
    a, label {
        cursor: pointer;
        font-size: .85em;
        background: #fff;
        display: inline-block;
        border-radius: 5px;
        padding: .5em 1em;
        border: 1px solid #ddd;
        color: #555;
        margin-left: .5rem;
        &:hover {
            background: #eee;
        }
    }
}


.recipe-editor-main {
    flex: 1;
    .meal-item-new {
        border: none;
        &:before {
            display: none;
        }
    }
}

.recipe-editor-desc {
    background: #fff;
    flex-basis: 280px;
    padding: 1em;
    font-size: .85em;
    >h2 {
        display: none;
    }
}

.recipe-editor-eval {
    display: none;
    /*
    order: 3;
    min-width: 300px;
    background: #fff;
    */
}

.radio-recipe-editor-nav {
    display: none;
}

.recipe-editor-nav {
    display: flex;
    flex-basis: auto;
    align-items: center;
    justify-content: center;
    padding: 1em 0 0;
    margin: -1em -1em 0 -1em;
    label {
        position: relative;
        top: 1px;
        display: block;
        padding: .85em 1.5em;
        background: none;
        color: #666;
        border: 1px solid transparent;
        border-bottom: none;
        border-radius: $radius $radius 0 0;
        cursor: pointer;
        i {
            display: none;
            margin-right: .25em;
            color: $primary;				
        }
        &:not(:last-child) {
            margin-right: .5em;
        }
        &:hover {
            color: #000;
        }
    }
}

#cb-recipe-editor-main:checked ~ .recipe-editor-nav {
    label:first-child {
        font-weight: bold;
        background: #fff;
        border: 1px solid #e5e5e5;
        border-bottom: none;
    }
}
#cb-recipe-editor-evaluation:checked ~ .recipe-editor-nav {
    label:last-child {
        font-weight: bold;
        background: #fff;
        border: 1px solid #e5e5e5;
        border-bottom: none;
    }
}

#cb-recipe-editor-main:checked ~ .plan {
    .recipe-editor-row {
        .recipe-editor-main, .recipe-editor-desc {
            display: block;
        }
        .recipe-editor-eval {
            display: none;
        }
    }
}

#cb-recipe-editor-evaluation:checked ~ .plan {
    .recipe-editor-row {
        .recipe-editor-main, .recipe-editor-desc {
            display: none;
        }
        .recipe-editor-eval {
            display: block;
            width: 100%;
            margin: 0 auto;
            background: #fff;
            max-width: 340px;
            overflow-x: hidden;
        }
    }
    .panel-evaluation {
        display: block !important;
    }
}


.panel-evaluation.evaluation-recipe-editor {
    .row .main h4 {
        font-size: 11px;
		color: $primary;
		background: none;
        background: #eee;
        color: #444;
		padding: 4px 6px;
		margin: .5rem 0 .5rem 0;
    }
    .row .main h4:first-child {
        margin-top: 0;
    }
    .row .main .eval-item {
    }

    .row .main .evaluation-group-title {
        background: #eee;
    }
    .row .main .eval-item-single {
        font-size: 10px;
    }
}

.panel-evaluation-recipe-editor {

}

@media screen and (max-width: 959px) {
    .panel-evaluation-recipe-editor {
        display: block !important
    }
}

@media screen and (max-width: 719px) {
    .recipe-editor-row {
        flex-wrap: wrap;
    }
    .recipe-editor-main {
        flex-basis: 100%;
    }
    .recipe-editor-desc {
        flex-basis: 100%;
        >h2 {
            display: block;
            color: $primary;
            margin: 0;
            padding: 1em 0 1em .85rem;
        }
    }
}


#cb-recipe-editor-description {
    display: none;
}

.recipe-editor-description {
    background: #fff;
    font-size: .85em;
    max-height: 0;
    overflow: hidden;
    .container {
        max-width: calc(740px - 2em);
        padding: 1em;
    }
}

#cb-recipe-editor-description:checked + .recipe-editor-description {
    max-height: none;
}

.evaluation-recipe-editor-highlighted {
    background: #ffa;
    padding: 1em;
    h2 {
        margin: 0 0 .5em 0;
        padding-left: .3rem;
        font-size: 1.15em;
    }
}