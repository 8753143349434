@keyframes fadeIn {
    0% { opacity: .3 }
    100% { opacity: 1 }
}

.dialog {
    animation: fadeIn .25s ease-out;    
    background: rgba(0,0,0,.4);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
}


.dialog-body {
    background: #fff;
    box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.3), 0px 24px 38px 3px rgba(0,0,0,0.24), 0px 9px 46px 8px rgba(0,0,0,0.22);
    width: 100%;
    max-width: 460px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: $radius;
    >h2 {
        font-size: 1.1em;
        font-weight: 500;
        margin: 0;
        display: flex;
        align-items: center;
        color: #000;
        font-weight: bold;
        background: #efefef;
        padding: .4rem 1rem;
        border-bottom: 1px solid #ccc;
        border-radius: $radius $radius 0 0;
        a {
            display: inline-block;
            margin-left: auto;
            padding: .35em .5em;
            &:hover {
                color: #000;
            }
        }
    }
}

.dialog-big .dialog-content {
    display: flex;
    flex-direction: column;
}
.dialog-big .dialog-body {
    max-width: none;
    width: 85vw;
    height: 85vh;
    max-width: 1080px;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 639px) {
        width: calc(100vw - 2rem);
    }
}

.dialog-flexible .dialog-content {
    display: flex;
    flex-direction: column;
}
.dialog-flexible .dialog-body {
    max-width: none;
    width: calc(100vw - 2rem);
    height: auto;
    max-width: 720px;
    display: flex;
    flex-direction: column;
}

.dialog-content {
    flex: 1;
    padding: 1rem;
    overflow-y: auto;
    .full {
        display: block;
        width: 100%;
        margin-bottom: .25em;
    }
    .input-recipe-name {
        font-size: 1.1em;
        padding: .1em .15em;
    }
    .divider {
        position: relative;
        font-size: 11px;
        text-align: center;
        color: #aaa;
        span {
            position: relative;
            background: #fff;
            padding: 0 .75em;
            z-index: 3;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 1px;
            background: #aaa;
            z-index: 2;
        }
    }
}

.dialog-content-nopadding .dialog-content {
    padding: 0;
}

.link-cancel {
    color: #999;
    text-decoration: underline;
    margin-left: 2em;
}

.dialog-close-button {
    text-align: center;
    padding: 1em 0 0;
    margin: 0;
    a {
        display: inline-block;
        background: $primary;
        color: #fff;
        font-size: .9em;
        padding: .5em 1.25em;
        border-radius: $radius;
        text-decoration: none;
        &:hover {
            background: #000;
        }
    }
}


.recipe-save-layout {
    display: flex;
    flex: 1;
    margin-left: -1em;
    .recipe-save-col {
        margin-left: 1em;
        display: flex;
    }
    .recipe-save-col:first-child {
        flex-basis: 440px;
    }
    .recipe-save-col:last-child {
        flex: 1;
        margin-left: 2em;
    }

    .filters-advanced-row {
        flex-wrap: wrap;
        >p {
            flex-basis: 100%;
            font-size: 12px;
            margin: 0 0 .5em 0;
        }
        .filters-advanced-item {
            label {
                font-size: 12px;
                flex-basis: 1%;
                white-space: nowrap;
                margin: 0 .25em .2em 0;
                min-width: auto;
                p {
                    margin: 0;
                    font-weight: normal;
                    padding: 3px 4px;
                    border: 1px solid #ccc;
                }
                span {
                    display: none;
                }
                input:checked + p {
                    background: $primary;
                    color: #fff;
                    border: 1px solid $primary;
                }
            }
        }
    }
}

.recipe-save-rows {
    flex: 1;
    .recipe-save-row {
        display: flex;
        margin: 4px 0;
        padding-right: 16px;
        input, span {
            flex: 1;
        }
        button {
            margin-left: 1em;
            width: 9rem;
            font-size: .85em;
            cursor: pointer;
        }
    }
    .recipe-save-row:first-child {
        margin-bottom: .5em;
        input, button {
            font-weight: bold;
        }
        input {
            padding: .5em 1em;
            background: #ffd;
            border: 1px solid #999;
        }
        button {
            background: $primary;
            color: #fff;
            border: none;
            &:hover {
                background: lighten($primary, 8%);
            }
        }
    }
    .recipe-save-row:not(:first-child) {
        font-size: .9em;
        span {
            display: block;
            padding: .25em .5em;
        }
        button {
            background: #888;
            color: #fff;
            border: none;
            &:hover {
                background: #555;
            }
        }
        &:hover {
            background: #ff6;
            button {
                background: #555;
            }
        }
    }
}










.energy-coefficients {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}
.energy-coefficient {
	flex-basis: calc(33.33% - 4px);
	text-align: center;
	line-height: 100%;
	margin: 2px;
	&:hover {
		background: #f1f1f1;
	}
	.content {
		padding: 8px;
		cursor: pointer;
		background: #efefef;
		color: #333;
	}
	p {
		margin: 0;
		font-size: .9em;
		line-height: 120%;
	}
	input[type="radio"] {
		display: none;
	}
	input[type="radio"]:checked ~ label {
		.content {
			background: $primary;
			color: #fff;
		}
	}
}


