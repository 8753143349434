.foods-copy-detail {
    text-align: center;
    padding: 2em 0;
    .food-icon {
        img {
            width: 100%;
            height: auto;
            max-width: 200px;
        }
    }
    button {
        cursor: pointer;
        background: $primary;
        border: none;
        color: #fff;
        padding: .75em 1.5em;
        border-radius: $radius;
        text-decoration: none;
    }
}
