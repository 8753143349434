.chat-form {
    padding: .5em;
    background: #efefef;
    table {
        width: 100%;
        td {
            padding: .5em;
            vertical-align: top;
        }
        td:nth-child(2) {
            width: 200px;
        }
        textarea {
            width: 100%;
            padding: .25em .5em;
            border: 1px solid #ccc;
            height: 8em;
        }
        button {
            cursor: pointer;
            background: $primary;
            color: #fff;
            border: none;
            padding: 1em;
            display: block;
            width: 100%;
            &:hover {
                background: lighten($primary, 8%);
            }
        }
    }  
}


.chat-messages {
    @include clearfix();
	margin: 1em 0;
}
.chat-message {
	background: #fff;
	padding: 1.5em;
	background: #ff9;
	margin: 0 0 1em 0;
	line-height: 120%;
	font-size: .9em;
	width: 60%;
    float: left;
    p {
        margin: 1em 0;
        &:first-child {
            margin-top: 0;
        }
    }
	.info {
		font-weight: bold;
		font-size: .85em;
    }
    img {
        width: 100%;
        height: auto;
    }
}

.chat-message-admin {
	background: #cef;
	float: right;
}
