.generator-row {
    display: flex;
}

.generator-setup {
    flex-basis: 300px;
    padding: 1em 0;

	h2 {
		font-size: 1.1em;
		color: $primary;
		font-size: 1.6em;
		text-align: center;
		font-weight: 400;
		margin: 1em 0;
	}
	.sub-hd {
		color: #444;
		font-size: .9em;
		margin: 1.5em 0 .5em 0;
		font-weight: bold;
		&:first-child {
			margin-top: 0;
		}
	}
	.profiles {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.profile {
			min-width: 160px;
			padding: 1em;
			text-align: center;
			label {
				cursor: pointer;
				display: block;
				.picture {
				}
				.picture-box {
					margin: 0 auto;
					width: 120px;
					height: 120px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					border-radius: 300px;
				}
				h3 {
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 1em;
					font-weight: normal;
					margin: 1em 0 0;
					color: #999;
				}
				input {
					display: none;
				}
				span {
					display: inline-flex;
					color: $primary;
					margin-left: .5em;
				}
				.no {
					display: inline-flex;
					color: #999;
				}
				.yes {
					display: none;
				}
				input:checked + h3 {
					color: $primary;
					font-weight: bold;
					.no {
						display: none;
					}
					.yes {
						display: inline-flex;
					}
				}
			}
		}
	}
	.options-row {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		label {
			cursor: pointer;
			flex-basis: 100%;
			display: flex;
			align-items: center;
			padding: 0 .5em;
			font-size: .9em;
			margin: 0 0 .25em 0;
			span {
				display: inline-block;
				width: 1.5em;
				height: 1.5em;
				border: 1px solid #aaa;
				background: #fff;
				padding: 4px;
				border-radius: 50%;
				margin-right: .5em;
				flex-shrink: 0;
			}
			input {
				display: none;
			}
			input:checked + span {
				border: 2px solid $primary;
				background: $primary;
			}
			select {
				margin-left: auto;
				margin-right: 2em;
			}
		}
	}
	.options-row-4 {
		label {
			flex-basis: calc(25% - 1em);
		}
	}
	.options-row-3 {
		label {
			flex-basis: calc(33.33% - 1em);
		}
	}
	.options-row-2 {
		label {
			flex-basis: calc(50% - 1em);
		}
	}

	@media screen and (max-width: 719px) {
		.options-row label, .options-row-4 label {
			flex-basis: calc(50% - 1em);
		}
		.options-row label, .options-row-3 label {
			flex-basis: calc(50% - 1em);
		}
	}
	@media screen and (max-width: 479px) {
		.options-row label{
			flex-basis: calc(100% - 1em);
		}
	}

	.submit {
		padding: 2em 0 0;
		display: flex;
		align-items: center;
		justify-content: center;
		button {
			@include button_default();
			border: none;
		}
	}    
}

.generator-results {
    flex: 1;
    padding-left: 2em;
    padding-top: 1em;

	h2 {
		color: #444;
		margin: 1em 0;
		font-size: 1.4em;
	}

}

.generator-100-warning {
    display: flex;
    align-items: center;
    margin: 1em 0;
    font-size: .85em;
    color: #d00;
    i {
        font-size: 1.5em;
        margin-right: .5rem;
    }
}




.generator-meals {
	display: flex;
	flex-wrap: wrap;
	padding: 1em;
	.meal {
		flex-basis: 100%;
        margin: 0 0 1em 0;
        border-radius: $radius $radius 0 0;
		label {
			display: flex;
			align-items: center;
			width: 100%;
			margin: 0 0 1em 0;
			background: #eee;
			color: #000;
			padding: .6em .5em;
			input {
				display: none;
			}
			>span {
				display: inline-flex;
				align-items: center;
				justify-content: center;
				width: 1.5em;
				height: 1.5em;
				background: #fff;
				i {
					color: #fff;
				}
			}
			input:checked + span {
				i {
					color: $primary;
				}
			}
			h2 {
                color: #444;
				font-size: 1.3em;
				margin: 0 0 0 .5em;
				display: flex;
				align-items: center;
				flex: 1;
                span {
                    margin-left: 1.5em;
                    font-size: 80%;
                    font-weight: normal;
                }
				aside {
                    margin-left: auto;
                    display: flex;
                    a {
                        font-size: .85rem;
                        background: #fff;
                        border: 1px solid #ddd;
                        padding: 5px 10px;
                        color: #000;
                        text-decoration: none;
                        margin-left: .25em;
                        font-weight: normal;
                        &:hover {
                            background: $primary;
                            color: #fff;
                            i {
                                color: #fff;
                            }
                        }
                        i {
                            margin-right: .3em;
                            color: $primary;
                        }
                    }
				}
			}	
		}
		.recipes {
			display: flex;
            flex-wrap: wrap;
            padding: 0 1em;
		}
		.recipe {
			display: flex;
			flex-basis: 100%;
			margin: 0 0 1em 0;
			.pic {
				flex-basis: 150px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				border-radius: $radius;
			}
			.info {
				flex-basis: calc(100% - 150px - 1em);
				margin-left: 1.5em;
				h3 {
					font-size: 1.1em;
					margin: 0 0 .5em 0;
				}
			}		
            .opts {
                display: flex;
                align-items: center;
                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: .85em;
                    width: 2.25em;
                    height: 2.25em;
                    border: 1px solid #aaa;
                    color: #aaa;
                    border-radius: 200px;
                    &:hover {
                        color: #444;
                        border: 1px solid #000;
                    }
                }
            }
			table {
				td:first-child {
					padding-right: 1em;
				}
			}
		}
	}
	@media screen and (max-width: 1023px) {
		.meal {
			.recipes {
				margin-left: 0;
			}
			.recipe {
				flex-basis: 100%;
				margin: 0 0 2em 0;
			}
		}
	}
	@media screen and (max-width: 639px) {
		padding: 0;
		.meal {
			label {
				>span {
					width: 1.15em;
					height: 1.15em;
				}				
				h2 {
					font-size: .9em;
				}
			}
			.recipe {
				.pic {
					flex-basis: 120px;
				}
			}
		}
	}	
	@media screen and (max-width: 479px) {
		.meal {
			.recipe {
				flex-wrap: wrap;
				.pic {
					flex-basis: 100%;
					height: 200px;
				}
				.info {
					flex-basis: 100%;
					margin: 0;
					padding: 1em 0 0;
				}
			}
		}
	}
}






.generator-insert-to-planner {
    margin: 2em 0 1em 0;
}



.generator-evaluation {
    margin-bottom: 3em;

    table {
        border: 1px solid #ccc;
        border-bottom: none;
        border-collapse: collapse;
        td, th {
            text-align: left;
            padding: .25em .5em;
            font-weight: normal;
            border-bottom: 1px solid #ccc;
        }
        th {
            padding-right: 1.5em;
        }
    }


	.row {
		display: flex;
		max-width: 800px;
		margin: 0 auto;
	}
	.col {
		flex-basis: calc(50% - 2em);
		margin: 0 1em;
		text-align: center;
		>div {
			margin: 1em 0;
			p {
				margin: 0 0 .25em 0;
				color: #444;
			}
			strong {
				color: #000;
				display: block;
				font-size: 1.4em;
			}
		}
	}
	.col:last-child {
		>div {
			strong {
				font-size: 1em;
			}
		}
	}
}


.generator-actions {
    display: flex;
    margin-bottom: 3em;
    .insert-to-planner {
        flex-basis: 50%;
        padding-right: 2em;
    }
    .save-as-menu {
        flex-basis: 50%;
        padding-left: 2em;
        display: block;
        input[type="text"], textarea {
            width: 100%;
            margin: 0 0 1em 0;
            border: 1px solid #aaa;
            padding: .25em .4em;
            border-radius: 3px;
        }
        textarea {
            height: 10em;
        }
    }
    button {
        @include button_default();
        border: none;
    }
}

.generator-insert-warning {
    color: #f00;
    // font-weight: bold;
}

.generator-menu-saved-info {
    margin: 1em 0;
    a {
        text-decoration: underline;
        color: $primary;
    }
}
































